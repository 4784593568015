<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel title="Jenis Hukum" class="panel panel-success">
            <!-- BAGIAN TOOLS LIST -->
            <b-row>
                <b-col md="12">
                    <b-card border-variant="dark" header-tag="header" footer-tag="footer">
                        <template #header>
                            <div class="pull-right">
                                <b-button class="pull-right" variant="primary" @click="create">
                                    <i class="fa fa-plus pr-1"></i> Tambah Data
                                </b-button>
                            </div>
                            <h6 class="mb-0">Tools</h6>
                        </template>

                        <!-- FILTER -->
                        <b-card-body class="p-0 m-0">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group :label-cols="3" label="Filter By" class="mb-1">
                                        <b-input-group size="md">
                                            <b-form-select v-model="filterBy" :options="fieldOptions"
                                                @change="changeFilterBy()">
                                                <option slot="first" :value="'all'">All Data</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                    <b-form-group :label-cols="3" label="Filter" class="mb-0"
                                        description="Type to Search or Click Clear to Stop Searching ">
                                        <b-input-group size="md">
                                            <b-form-input v-model="filter"
                                                placeholder="Type to Search" debounce="500" />
                                            <b-input-group-append>
                                                <b-btn :disabled="!filter" @click="filter = ''" variant="info">Clear
                                                </b-btn>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group :label-cols="3" label="Sort" class="mb-1">
                                        <b-input-group size="md">
                                            <b-form-select v-model="sortBy" :options="fieldOptions">
                                                <option slot="first" :value="null">-- none --</option>
                                            </b-form-select>
                                            <b-form-select :disabled="!sortBy" v-model="sortDesc" slot="append">
                                                <option :value="false">Asc</option>
                                                <option :value="true">Desc</option>
                                            </b-form-select>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                        <!-- end FILTER -->

                        <!--
                        <div class="pull-right mx-0 my-0">
                            <b-button variant="primary" to="/menu/sptrd/pendaftaran">
                                <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                            </b-button>
                        </div> -->
                        <template #footer>
                            <!-- <em>Footer Slot</em> -->
                            <div class="pull-right">
                                <b-button variant="primary" @click="reload">
                                    <i class="fa fa-sync-alt pr-1"></i> Refresh
                                </b-button>
                                <!-- <b-button class="ml-2" variant="primary" to="/menu/sptrd/pendaftaran">
                                    <i class="fa fa-plus pr-1"></i> Tambah SPTRD
                                </b-button> -->
                            </div>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
            <!-- end BAGIAN TOOLS LIST -->

            <hr class="my-2">

            <!-- Data Table & Pagination -->
            <b-table show-empty small responsive id="tableInduk" ref="tableInduk" head-variant="light" :hover="true"
                :busy.sync="isBusy" :items="myGetData" :fields="fields" :current-page="currentPage" :per-page="perPage"
                :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" bordered>
                <!-- 
                loading table
                -------------------------------------------------- -->
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- 
                pengaturan kolom
                -------------------------------------------------- -->
                <!-- header kolom -->
                <template #head()="data">
                    {{ data.label }}
                </template>
                <!-- lebar kolom -->
                <template v-slot:table-colgroup="scope">
                    <col v-for="field in scope.fields" :key="field.key" :style="{
                            width: field.key === 'index' ? '35px' : 
                                field.key === 'singkatan' ? '150px' :
                                field.key === 'actions' ? '103px' : ''
                        }" />
                </template>
                <!-- 
                index (nomor baris)
                -------------------------------------------------- -->
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <!-- 
                tombol aksi
                -------------------------------------------------- -->
                <template v-slot:cell(actions)="data">
                    <b-dropdown split size="sm" variant="info">
                        <template #button-content>
                            <i class="fa fa-cogs pr-1"></i> Aksi
                        </template>
                        <b-dropdown-item @click="view(data.item.id)"><i class="fa fa-eye"></i>
                            Lihat</b-dropdown-item>
                        <b-dropdown-item @click="edit(data.item.id)"><i class="fa fa-edit"></i>
                            Edit</b-dropdown-item>
                        <b-dropdown-item @click="deleteConfirm(data.item.id, data.item)"><i class="fa fa-trash-alt"></i>
                            Hapus</b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <!-- end of table -->

            <div class="divider"></div>

            <!-- pagination section -->
            <b-row>
                <div class="divider"></div>
                <b-col md="6" class="my-0">
                    <b-form-group :label-cols="2" label="Per page" class="mb-0">
                        <b-form inline>
                            <b-form-select :options="pageOptions" style="width: 100px;" v-model="perPage" />
                            <label class="ml-1 mr-1">/</label>
                            <b-form-input :disabled="true" v-model="totalRows" style="width: 100px;"
                                class="text-right" />
                            <label class="ml-1">Rows</label>
                        </b-form>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-0">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
                        size="md" class="my-0 "></b-pagination>
                </b-col>
            </b-row>
            <!-- end pagination section -->
        </panel>

        <!-- 
        Modal untuk Form create() & edit()
        -------------------------------------------------- -->
        <b-modal :title="formModal.title" v-model="formModal.show" id="modalForm" hide-footer no-close-on-backdrop
            size="lg">
            <form @submit.prevent="actButton.edit ? update(dataPrevious.id) : store()" @keydown="form.onKeydown($event)"
                autocomplete="off">
                <div class="form-group row m-3">
                    <label class="col-md-2 col-form-label font-weight-bold">
                        Singkatan 
                        <span :class="{'text-danger': true, 'hide': actButton.type==='view'}">*</span>
                        <!-- <span class="text-danger" v-if="actButton.type!='view'">*</span> -->
                    </label>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-sm-6">
                                <input type="text" :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('singkatan'),
                                    }" v-model="form.singkatan" :disabled="actButton.type==='view'" placeholder="" />
                            </div>
                        </div>
                        <em v-if="form.errors.has('singkatan')" class="form-text text-danger mt-0">
                            {{ formModal.errors.singkatan[0] }}
                        </em>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-2 col-form-label font-weight-bold">
                        Nama 
                        <span :class="{'text-danger': true, 'hide': actButton.type==='view'}">*</span>
                        <!-- <span class="text-danger" v-if="actButton.type!='view'">*</span> -->
                    </label>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-sm-12">
                                <input type="text" :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('nama'),
                                    }" v-model="form.nama" :disabled="actButton.type==='view'" placeholder="" />
                            </div>
                        </div>
                        <em v-if="form.errors.has('nama')" class="form-text text-danger mt-0">
                            {{ formModal.errors.nama[0] }}
                        </em>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-2 col-form-label font-weight-bold">
                        Deskripsi
                    </label>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-sm-12">
                                <b-form-textarea id="textarea-no-resize" rows="6" 
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('deskripsi'),
                                    }" 
                                    v-model="form.deskripsi" no-resize
                                    :disabled="actButton.type==='view'" placeholder=""></b-form-textarea>
                            </div>
                        </div>
                        <em v-if="form.errors.has('deskripsi')" class="form-text text-danger mt-0">
                            {{ formModal.errors.deskripsi[0] }}
                        </em>
                    </div>
                </div>
                <!-- Form Button -->
                <hr v-if="actButton.type!='view'" />
                <div class="row" v-if="actButton.type!='view'">
                    <div class="col-md-6">
                        <span>
                            <b-button variant="primary" class="mr-2" @click="reset">
                                <i class="fa fa-undo"></i>
                                Reset
                            </b-button>
                        </span>
                    </div>
                    <div class="col-md-6">
                        <span class="float-right">
                            <b-button variant="primary" type="submit" class="mr-2" 
                                :disabled="formModal.busy">
                                <span v-if="formModal.busy">
                                    <b-spinner variant="primary" label="Spinning" small></b-spinner>
                                </span>
                                <i class="fa fa-save" v-else></i>
                                Simpan
                            </b-button>
                            <b-button variant="outline-secondary" @click="close">
                                <i class="fa fa-times"></i>
                                Tutup
                            </b-button>
                        </span>
                    </div>
                </div>
            </form>
        </b-modal>

    </div>
</template>

<script>
    /* eslint-disable */
    // eslint-disable-next-line
    // eslint-disable vue/no-unused-vars
    // eslint-disable-line no-unused-vars
    import HRetribusi from "../../../components/npwr/HRetribusi.vue";
    import axios from "axios";
    import hapusStorage from "@/helper/hapusLocalStore";
    import {
        mapState
    } from "vuex";

    export default {
        mixins: [hapusStorage],
        components: {
            HRetribusi
        },
        data() {
            return {
                // ==================================================
                // Data & Table
                // --------------------------------------------------
                // tools table
                isBusy: false,
                sortBy: null,
                sortDesc: false,
                filter: null,
                filterBy: "all",
                pageOptions: [5, 10, 15, 25],
                perPage: 10,
                currentPage: 1,
                totalRows: 0,
                // table setting
                fields: [{
                        key: "index",
                        label: "No.",
                        tdClass: "text-right"
                    },
                    {
                        key: "singkatan",
                        label: "Singkatan",
                        sortable: true,
                        value: "singkatan"
                    },
                    {
                        key: "nama",
                        label: "Nama",
                        sortable: true,
                        value: "nama"
                    },
                    {
                        key: "deskripsi",
                        label: "Deskripsi",
                        sortable: false,
                        value: "deskripsi"
                    },
                    {
                        key: "actions",
                        label: "Opsi"
                    }
                ],
                // actions button
                actButton: {
                    type: '', // view, create, edit, delete
                    edit: false
                },

                // ==================================================
                // Form & FormData for CRUD
                // --------------------------------------------------
                dataPrevious: [],
                form: new Form({
                    kode: "",
                    singkatan: "",
                    nama: "",
                    deskripsi: "",
                    no_urut: ""
                }),
                formModal: {
                    title: '',
                    show: false,
                    busy: false,
                    button: {
                        submit: {
                            disabled: true
                        }
                    },
                    errors: [],
                },
            };
        },
        mounted() {
            // this.$store.dispatch("PemerintahDaerah/getPerangkat");
            // this.getTahun();
        },
        computed: {
            // Create an options list from our fields
            fieldOptions() {
                return this.fields
                    .filter(f => f.sortable && f.value)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.value
                        };
                    });
            },
        },
        methods: {
            // ==================================================
            // Data & Table
            // --------------------------------------------------
            // datatable
            myGetData(ctx) {
                // ctx = berasal dari tag <b-table></b-table>
                this.$insProgress.start();
                this.isBusy = true;
                let promise = axios.get("/api/data-induk/jdih/jenis-hukum", {
                    params: {
                        page: ctx.currentPage,
                        perpage: ctx.perPage,
                        sortby: ctx.sortBy,
                        sortdesc: ctx.sortDesc,
                        filter: ctx.filter,
                        filterby: this.filterBy
                    }
                });
                return promise
                    .then(response => {
                        const items = response.data.data;
                        // Data Pagination
                        this.configPagination(response.data.meta);
                        // Here we could override the busy state, setting isBusy to false
                        this.isBusy = false;
                        this.$insProgress.finish();
                        return items;
                    })
                    .catch(error => {
                        // Here we could override the busy state, setting isBusy to false
                        this.isBusy = false;
                        this.$insProgress.fail();
                        if (error.response.status === 401) {
                            // hapus storage mixins
                            this.clearAll();
                        }else{
                            console.log(error)
                        }
                        // Returning an empty array, allows table to correctly handle
                        // internal busy state in case of error
                        return [];
                    });
            },
            // konfigurasi data pagination
            configPagination(data){
                this.totalRows = data.total;
            },
            // reload / refresh data table ketika data filter berubah
            changeFilterBy() {
                if (this.filter) {
                    this.$refs.tableInduk.refresh();
                }
            },
            // reload / refresh data table ketika tombol refresh diklik
            reload() {
                // with id="tableInduk" in tag b-table
                // this.$root.$emit('bv::refresh::table', 'tableInduk')
                // with property ref="tableInduk" in tag b-table
                this.$refs.tableInduk.refresh();
            },

            // ==================================================
            // Form & FormData
            // --------------------------------------------------
            // membersihkan formulir ke data awal
            clean() {
                this.form.reset()
                this.form.clear()
                this.dataPrevious = []
                this.actButton.type = ''
                this.actButton.edit = false
                this.formModal.errors = []
                // stop semua proses ...
                this.formProsesEnd();
            },
            // mengembalikan data formulir ke data sebelumnya
            reset() {
                if (this.actButton.edit) {
                    this.form.reset()
                    this.form.clear()
                    this.form.fill(this.dataPrevious)
                    this.formModal.busy = false
                    this.formModal.errors = []
                } else {
                    this.clean()
                }
            },
            // pengaturan page saat proses sedang berjalan ...
            formProsesStart() {
                this.formModal.busy = true
                this.formModal.button.submit.disabled = true
                this.$insProgress.start();
            },
            // pengaturan page saat proses telah selesai ...
            formProsesEnd() {
                this.$insProgress.finish();
                this.formModal.busy = false
                this.formModal.button.submit.disabled = false
            },
            // create = menampilkan formulir menambah data baru
            view(id) {
                this.clean()
                // pengaturan form ...
                this.actButton.type = 'view';
                this.actButton.edit = false;
                this.formModal.title = 'Data Jenis Hukum';
                this.formModal.show = true;
                // or ...
                // this.$root.$emit("bv::show::modalForm", "modalForm");
                // get data by ...
                this.show(id);
            },
            // create = menampilkan formulir menambah data baru
            create() {
                this.clean()
                // pengaturan form ...
                this.actButton.type = 'create';
                this.actButton.edit = false;
                this.formModal.title = 'Tambah Data Jenis Hukum';
                this.formModal.show = true;
                // or ...
                // this.$root.$emit("bv::show::modalForm", "modalForm");
            },
            // edit = menampilkan formulir merubah data lama
            edit(id) {
                this.clean()
                // pengaturan form ...
                this.actButton.type = 'edit';
                this.actButton.edit = true;
                this.formModal.title = 'Edit Data Jenis Hukum';
                this.formModal.show = true;
                // or ...
                // this.$root.$emit("bv::show::modalForm", "modalForm");
                // get data by ...
                this.show(id);
            },
            // delete = menampilkan formulir atau pesan peringatan penghapusan data
            deleteConfirm(id, data) {
                this.actButton.type = 'delete';
                this.formModal.title = 'Hapus Data Jenis Hukum';
                this.$swal({
                    icon: "question",
                    title: "<h3 class='mb-0'><strong>Konfirmasi Penghapusan Data</strong></h3>",
                    text: "Anda yakin akan menghapus data " + data.singkatan + " ?",
                    showCloseButton: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Ya, hapus !",
                    cancelButtonText: "Cancel",
                    allowOutsideClick: false,
                    buttonsStyling: false,
                    customClass: {
                        confirmButton: 'btn btn-primary mr-2',
                        cancelButton: "btn btn-default"
                    },
                }).then(result => {
                    if (result.isConfirmed) {
                        this.destroy(id);
                    }
                });
            },
            // close = menutup formulir menambah atau merubah data
            close() {
                this.clean()
                this.formModal.show = false;
                // or ...
                // this.$root.$emit("bv::hide::modalForm", "modalForm");
            },

            // ==================================================
            // Eksekusi Data ke BackEnd
            // --------------------------------------------------
            // store
            store() {
                this.formProsesStart();
                this.form
                    .post("/api/data-induk/jdih/jenis-hukum")
                    .then((response) => {
                        this.formProsesEnd();
                        this.reload();
                        // --------------------------------------------------
                        // alert success 1 ...
                        this.$swal({
                            icon: "success",
                            title: "<h3 class='mb-0'><strong>" +
                                response.data.status.title +
                                "</strong></h3>",
                            text: response.data.status.message,
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "Oke, terimakasih !",
                            allowOutsideClick: false,
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-info btn-sm',
                            },
                            timer: 5000,
                        });
                        // this.clean();
                        // --------------------------------------------------
                        // alert success 2 ...
                        // tambah data baru atau tutup form ?
                        // this.$swal({
                        //     icon: "success",
                        //     title: "<h3 class='mb-0'><strong>"
                        //         +response.data.status.title+
                        //         "</strong></h3>",
                        //     text: response.data.status.message,
                        //     showConfirmButton: true,
                        //     showCancelButton: true,
                        //     confirmButtonText: "Tambah Data Baru",
                        //     cancelButtonText: "Tutup",
                        //     buttonsStyling: false,
                        //     customClass: {
                        //         confirmButton: "btn btn-info mr-2",
                        //         cancelButton: "btn btn-default"
                        //     },
                        //     timer: 5000,
                        // }).then(result => {
                        //     if (result.isConfirmed) {
                        //         this.clean()
                        //     }else{
                        //         this.close()
                        //     }
                        // });
                    })
                    .catch((error) => {
                        this.formProsesEnd();
                        if (error.response.status === 422) {
                            this.formModal.errors = error.response.data;
                        } else if (error.response.status === 401) {
                            // hapus token mixins
                            // this.clearAll();
                        }else{
                            console.log(error)
                        }
                    });
            },
            // show data berdasarkan id
            show(id) {
                axios
                    .get("/api/data-induk/jdih/jenis-hukum/" + id)
                    .then((response) => {
                        this.dataPrevious = response.data.data;
                        this.form.fill(this.dataPrevious);
                        this.formModal.errors = [];
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            // hapus token mixins
                            this.clearAll();
                        }else{
                            console.log(error)
                        }
                    });
            },
            // update
            update(id) {
                this.formProsesStart();
                this.form
                    .put("/api/data-induk/jdih/jenis-hukum/" + id)
                    .then((response) => {
                        this.formProsesEnd();
                        this.reload();
                        this.$swal({
                            icon: "success",
                            title: "<h3 class='mb-0'><strong>" +
                                response.data.status.title +
                                "</strong></h3>",
                            text: response.data.status.message,
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "Oke, terimakasih !",
                            allowOutsideClick: false,
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-info',
                            },
                            timer: 5000,
                        });
                        this.clean();
                        this.close();
                    })
                    .catch((error) => {
                        this.formProsesEnd();
                        if (error.response.status === 422) {
                            this.errors = error.response.data;
                        } else if (error.response.status === 401) {
                            // ...
                        } else if (error.response.status === 405) {
                            this.$swal({
                                icon: "error",
                                title: "<h3 class='mb-0'><strong>" +
                                    response.data.status.title +
                                    "</strong></h3>",
                                text: error.response.data.status.message,
                                showConfirmButton: true,
                                showCloseButton: true,
                                confirmButtonText: "OKE",
                                allowOutsideClick: false,
                                buttonsStyling: false,
                                customClass: {
                                    confirmButton: 'btn btn-info',
                                },
                                timer: 5000,
                            });
                        }else{
                            console.log(error)
                        }
                    });
            },
            // destroy
            destroy(id) {
                this.formProsesStart();
                axios
                    .delete("/api/data-induk/jdih/jenis-hukum/" + id)
                    .then((response) => {
                        this.formProsesEnd();
                        this.reload();
                        this.$swal({
                            icon: "success",
                            title: "<h3 class='mb-0'><strong>" +
                                response.data.status.title +
                                "</strong></h3>",
                            text: response.data.status.message,
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "Oke, terimakasih !",
                            allowOutsideClick: false,
                            buttonsStyling: false,
                            customClass: {
                                confirmButton: 'btn btn-info',
                            },
                            timer: 5000,
                        });
                        this.clean();
                        this.close();
                    })
                    .catch((error) => {
                        this.formProsesEnd();
                        if (error.response.status === 401) {
                            // ...
                        } else if (error.response.status === 405) {
                            this.$swal({
                                icon: "error",
                                title: "<h3 class='mb-0'><strong>" +
                                    response.data.status.title +
                                    "</strong></h3>",
                                text: error.response.data.status.message,
                                showConfirmButton: true,
                                showCloseButton: true,
                                confirmButtonText: "OKE",
                                allowOutsideClick: false,
                                buttonsStyling: false,
                                customClass: {
                                    confirmButton: 'btn btn-info',
                                },
                                timer: 5000,
                            });
                        }else{
                            console.log(error)
                        }
                    });
            }
        }
    };
</script>